import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import DataPrivacyPage from "../components/DataPrivacy/DataPrivacyPage";

const DataPrivacy = () => {
  return (
    <Layout title={"Data and privacy"}>
      <Navbar />
      <DataPrivacyPage />
      <Footer />
    </Layout>
  );
};

export default DataPrivacy;
