import React from "react";
import bannerImg from "../../assets/images/hyfen/privacy-img.png";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";

const DatPrivacyPage = () => {
  return (
    <div className="dataprivacy-page">
      <section className="section1 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <h1 className="pb-4">Data &amp; Privacy</h1>
              <p className="text-4 para-1">
                Your data privacy and data rights are important to us. We treat the information you provide us with the strictest of confidence, and we take
                steps to safeguard it.
              </p>
            </div>

            <div className="col-12 col-md-7">
              <div className="mt-5 mt-md-n3 pb-100">
                <div className="data-privacy-accordion">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>1.0 About this policy</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Hyfen Hub Pty Ltd ABN 30645094079 (<b>we</b>, <b>our</b> or <b>us</b>), understands that protecting your personal information is
                          important. This Data and Privacy Policy (<b>Policy</b>) sets out our commitment to protecting the privacy of personal information
                          provided to us, or otherwise collected by us when providing our “Hyfen8” software as a service and all its capabilities, including the
                          storage of data, the use thereof to determine the relative strength of intangible assets, the analysis of the data provided by you,
                          the generation of predictive algorithms, and the prediction of types of learning opportunities that a business could benefit from, and
                          when providing access and the use of, our website and digital platform, available at hyfen8.com to you (<b>Services</b>) or when
                          otherwise interacting with you. It also sets out certain rights and obligations that you have and that we have in respect of data and
                          intellectual property.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>2.0 Your Data</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ol>
                          <li>
                            <b>2.1</b> Your Data: You own all data, information or content you upload into our software or provide to us when using our Services
                            (<b>Your Data</b>), as well as any data or information output from our software using Your Data as input (Output Data). Note that
                            Output Data does not include the Analytics (as described below).
                          </li>
                          <li>
                            <b>2.2</b> You agree that you are solely responsible for all of Your Data that you make available on or through our software or by
                            making use of our Services. You represent and warrant that:
                            <ul className="sub-list">
                              <li>
                                you are either the sole and exclusive owner of Your Data or you have all rights, licences, consents and releases that are
                                necessary to grant to us the rights in Your Data (as contemplated in this Policy); and
                              </li>
                              <li>
                                neither Your Data nor the posting, uploading, publication, submission or transmission of Your Data or our use of Your Data on,
                                through or by means of our software will infringe, misappropriate or violate a third party’s intellectual property rights, or
                                rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>2.3</b> You acknowledge and agree that we may monitor, analyse and compile statistical and performance information based on
                            and/or related to your use of our software and/or Services, in an aggregated and anonymised format (<b>Analytics</b>). You
                            acknowledge and agree that we own all rights in the Analytics, and that we may use the Analytics for our own internal business
                            purposes, provided that the Analytics do not contain any identifying information.
                          </li>
                          <li>
                            <b>2.4</b> We do not endorse or approve, and are not responsible for, any of Your Data.{" "}
                          </li>
                          <li>
                            <b>2.5</b> You acknowledge and agree that our software and the integrity and accuracy of the Output Data is reliant on the accuracy
                            and completeness of Your Data, and the provision by you of Your Data that is inaccurate or incomplete may affect the use, output and
                            operation of our software or Services.
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>3.0 Security and Security Incidents</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ol>
                          <li>
                            <b>3.1</b> Where possible, we will establish and maintain appropriate, reasonable technical and organisational security measures in
                            accordance with good industry practice to keep Your Data secure. This notwithstanding, we do not warrant the security of Your Data
                          </li>
                          <li>
                            <b>3.2</b> If either of us becomes aware of or reasonably suspects that a security incident has occurred arising from our provision
                            of the Services, such that Your Data has or may have been compromised (for example, unauthorised access) (each a{" "}
                            <b>Security Incident</b>), the relevant party must promptly notify the other party and we agree to, within a reasonable time:
                            <ul className="sub-list">
                              <li>
                                conduct an investigation to determine whether a Security Incident has occurred, and where one has, the cause and impact of it on
                                Your Data; and
                              </li>
                              <li>
                                where a Security Incident is deemed to have occurred, remediate the Security Incident to the extent that this is operationally,
                                commercially and technically feasible.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>3.3</b> You agree that we may suspend the Services where a Security Incident has or may have occurred and this is considered
                            necessary or prudent (as determined by us, at our reasonable discretion) to address or deal with the Security Incident.
                          </li>
                          <li>
                            <b>3.4</b> Notifiable Data Breaches
                            <ol className="sub-list">
                              <li>
                                <b>3.4.1</b> This clause will only apply to the extent to the extent the notifiable data breaches scheme under Part IIIC of the
                                Privacy Act 1988 (Cth) (<b>Notifiable Data Breaches Scheme</b>) applies to us.
                              </li>
                              <li>
                                <b>3.4.2</b> If as a result of our investigations as provided for in this Policy, we believe a Security Incident has occurred
                                that we consider to be notifiable under the Notifiable Data Breaches Scheme, we will:
                                <ul className="sub-list">
                                  <li>promptly notify you of this by telephone or email;</li>
                                  <li>
                                    provide notice to the Office of the Australian Information Commissioner in accordance with the Notifiable Data Breaches
                                    Scheme; and
                                  </li>
                                  <li>
                                    be the sole party to notify the individuals who are likely to be at risk of serious harm arising from the Security Incident.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <b>3.4.3</b> Where we do not have the contact details of affected individuals, we will provide you with a statement to provide
                                to affected individuals.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>4.0 The personal information we collect</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Personal information: is information or an opinion, whether true or not and whether recorded in a material form or not, about an
                          individual who is identified or reasonably identifiable.
                        </p>
                        <p>The types of personal information we may collect about you include:</p>
                        <ol>
                          <li>
                            <b>4.1</b> As a user of our software, website and/or platform:
                            <ul className="sub-list">
                              <li>your name;</li>
                              <li>your employer and job title;</li>
                              <li>your contact details, including email address, mailing address, street address and/or telephone number;</li>
                              <li>your preferences and/or opinions;</li>
                              <li>your credit card or other payment and bank account details (including through our third-party payment processor, if any);</li>
                              <li>
                                information you provide to us in respect of our account recovery functions related to your account with us and the use thereof
                                by you;
                              </li>
                              <li>information you provide to us, including through feedback, customer surveys or otherwise;</li>
                              <li>details of services we have provided to you and/or that you have enquired about, and our response to you;</li>
                              <li>support requests submitted to us and our response to you;</li>
                              <li>
                                information about your access and use of our Services, including through the use of Internet cookies, your communications with
                                our online Services, the type of browser you are using, the type of operating system you are using and the domain name of your
                                Internet service provider;
                              </li>
                              <li>
                                your browser session and geo-location data, device and network information (including your IP address), statistics on page views
                                and sessions, acquisition sources, search queries and/or browsing behaviour;
                              </li>
                              <li>
                                additional personal information that you provide to us, directly or indirectly, through your use of our Services, associated
                                applications, associated social media platforms and/or accounts from which you permit us to collect information; and
                              </li>
                              <li>any other personal information requested by us and/or provided by you or a third party.</li>
                            </ul>
                          </li>
                          <li>
                            <b>4.2</b> When you visit our website:
                            <ul className="sub-list">
                              <li>
                                your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition
                                sources, search queries and/or browsing behaviour;
                              </li>
                              <li>your contact details, including email address and/or telephone number;</li>
                              <li>your preferences and/or opinions;</li>
                              <li>information you provide to us, including through feedback, customer surveys or otherwise;</li>
                              <li>details of services we have provided to you and/or that you have enquired about, and our response to you;</li>
                              <li>support requests submitted to us and our response to you;</li>
                              <li>
                                information about your access and use of our Services, including through the use of Internet cookies, your communications with
                                our online Services, the type of browser you are using, the type of operating system you are using and the domain name of your
                                Internet service provider;
                              </li>
                              <li>
                                additional personal information that you provide to us, directly or indirectly, through your use of our Services, associated
                                applications, associated social media platforms and/or accounts from which you permit us to collect information; and
                              </li>
                              <li>any other personal information requested by us and/or provided by you or a third party.</li>
                            </ul>
                          </li>
                          <li>
                            <b>4.3</b> Sensitive information
                            <ul className="sub-list">
                              <li>
                                Sensitive information is a sub-set of personal information that is given a higher level of protection. Sensitive information
                                means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional
                                associations or memberships, philosophical beliefs, sexual orientation or practices, criminal records, health information or
                                biometric information.
                              </li>
                              <li>
                                Sensitive information: The types of sensitive information we may collect about you include your professional associations and
                                memberships.
                              </li>
                            </ul>
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>5.0 How we collect personal information</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>We collect personal information in a variety of ways, including:</p>
                        <ol>
                          <li>
                            <b>5.1</b> Directly: We collect personal information which you directly provide to us, including when you register for an account,
                            through the ‘contact us’ form on our website, when you interact with or utilise our Services, via email, when you provide feedback
                            to us on our website, when you request our assistance via email, or over the telephone, or when otherwise communicating with you.
                          </li>
                          <li>
                            <b>5.2</b> Indirectly: We may collect personal information which you indirectly provide to us while interacting with us or third
                            parties, such as when you use our Services, when you review our Services online, via social media or blogs, or over the telephone.
                          </li>
                          <li>
                            <b>5.3</b> From third parties: We collect personal information from third parties, such as third-party payment providers, service
                            providers who have referred you to us, details of your use of our website from our analytics and cookie providers and marketing
                            providers. See the “Cookies” section below for more detail on the use of cookies.
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>6.0 Why we collect, hold, use and disclose personal information</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ol>
                          <li>
                            <b>6.1</b> As a user of our software, website and/or platform:
                            <ul className="sub-list">
                              <li>to enable you to access and use our software, including to provide you with a login;</li>
                              <li>to provide our Services to you;</li>
                              <li>to run promotions, competitions and/or offer additional benefits to you;</li>
                              <li>
                                to contact and communicate with you about our Services, including in response to any support requests you lodge with us or other
                                enquiries you make with us;
                              </li>
                              <li>for internal record keeping, administrative, invoicing and billing purposes;</li>
                              <li>
                                for analytics, market research and business development, including to operate and improve our Services and associated
                                applications, if any;
                              </li>
                              <li>
                                for advertising and marketing, including to send you promotional information about our services and other information that we
                                consider may be of interest to you;
                              </li>
                              <li>to comply with our legal obligations and resolve any disputes that we may have; and/or</li>
                              <li>if otherwise required or authorised by law.</li>
                            </ul>
                          </li>
                          <li>
                            <b>6.2</b> When you visit our website:
                            <ul className="sub-list">
                              <li>to provide our website to you;</li>
                              <li>to contact and communicate with you about any enquiries you make with us via our website;</li>
                              <li>to comply with our legal obligations and resolve any disputes that we may have; and/or</li>
                              <li>if otherwise required or authorised by law.</li>
                            </ul>
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>7.0 Our disclosures of personal information to third parties</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ol>
                          <li>
                            <b>7.1</b> We may disclose personal information to:
                            <ul className="sub-list">
                              <li>
                                third party service providers for the purpose of enabling them to provide their services, to us, including (without limitation)
                                IT service providers, data storage, web-hosting and server providers, email marketing providers, debt collectors, maintenance or
                                problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;
                              </li>
                              <li>our employees, contractors and/or related entities;</li>
                              <li>our existing or potential agents or business partners;</li>
                              <li>sponsors or promoters of any promotions or competition we run;</li>
                              <li>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</li>
                              <li>courts, tribunals and regulatory authorities, in the event you fail to pay for services we have provided to you;</li>
                              <li>
                                courts, tribunals, regulatory authorities and law enforcement officers, as required or authorised by law, in connection with any
                                actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;
                              </li>
                              <li>
                                third parties to collect and process data, such as Google Analytics (To find out how Google uses data when you use third party
                                websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL Google may use from time to
                                time), Facebook Pixel or other relevant analytics businesses; and
                              </li>
                              <li>any other third parties as required or permitted by law, such as where we receive a subpoena.</li>
                            </ul>
                          </li>
                          <li>
                            <b>7.2</b> Google Analytics. We may enable Google Analytics Advertising Features including Remarketing Features, Advertising
                            Reporting Features, Demographics and Interest Reports, Store Visits, Google Display Network Impression reporting etc.. We and
                            third-party vendors use first-party cookies (such as the Google Analytics cookie) or other first-party identifiers, and third-party
                            cookies (such as Google advertising cookies) or other third-party identifiers together.
                          </li>
                          <li>
                            You can opt-out of Google Analytics Advertising Features including using a Google Analytics Opt-out Browser add-on found{" "}
                            <a href="#">here</a>. To opt-out of personalised ad delivery on the Google content network, please visit Google’s Ads Preferences
                            Manager <a href="#">here</a> or if you wish to opt-out permanently even when all cookies are deleted from your browser you can
                            install their plugin <a href="#">here</a>. To opt out of interest-based ads on mobile devices, please follow these instructions for
                            your mobile device: On android open the Google Settings app on your device and select “ads” to control the settings. On iOS devices
                            with iOS 6 and above use Apple’s advertising identifier. To learn more about limiting ad tracking using this identifier, visit the
                            settings menu on your device.
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>8.0 Overseas disclosure</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We may store personal information overseas. Where we disclose your personal information to the third parties listed above, these third
                          parties may also store, transfer or access personal information outside of Australia.
                        </p>
                        <p>
                          Unless we seek and receive your consent to an overseas disclosure of your personal information, we will only disclose your personal
                          information to countries with laws which protect your personal information in a way which is substantially similar to the Australian
                          Privacy Principles and/or we will take such steps as are reasonable in the circumstances to require that overseas recipients protect
                          your personal information in accordance with the Australian Privacy Principles.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>9.0 Your rights and controlling your personal information</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ol>
                          <li>
                            <b>9.1</b> Your choice: Please read this Policy carefully. If you provide personal information to us, you understand we will
                            collect, hold, use and disclose your personal information in accordance with this Policy. You do not have to provide personal
                            information to us, however, if you do not, it may affect our ability to provide our Services to you and your use of our Services.
                          </li>
                          <li>
                            <b>9.2</b> Information from third parties: If we receive personal information about you from a third party, we will protect it as
                            set out in this Policy. If you are a third party providing personal information about somebody else, you represent and warrant that
                            you have such person’s consent to provide the personal information to us.
                          </li>
                          <li>
                            <b>9.3</b> Anonymity: Where practicable we will give you the option of not identifying yourself or using a pseudonym in your
                            dealings with us.
                          </li>
                          <li>
                            <b>9.4</b> Restrict and unsubscribe: To object to processing for direct marketing/unsubscribe from our email database or opt-out of
                            communications (including marketing communications), please contact us using the details below or opt-out using the opt-out
                            facilities provided in the communication.
                          </li>
                          <li>
                            <b>9.5</b> Access: You may request access to the personal information that we hold about you. An administrative fee may be payable
                            for the provision of such information. Please note, in some situations, we may be legally permitted to withhold access to your
                            personal information. If we cannot provide access to your information, we will advise you as soon as reasonably possible and provide
                            you with the reasons for our refusal and any mechanism available to complain about the refusal. If we can provide access to your
                            information in another form that still meets your needs, then we will take reasonable steps to give you such access.
                          </li>
                          <li>
                            <b>9.6</b> Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or
                            misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information found to be
                            inaccurate, out of date, incomplete, irrelevant or misleading. Please note, in some situations, we may be legally permitted to not
                            correct your personal information. If we cannot correct your information, we will advise you as soon as reasonably possible and
                            provide you with the reasons for our refusal and any mechanism available to complain about the refusal.
                          </li>
                          <li>
                            <b>9.7</b> Complaints: If you wish to make a complaint, please contact us using the details below and provide us with full details
                            of the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
                            investigation and the steps we will take in response to your complaint. If you are not satisfied with our response, you also have
                            the right to contact the Office of the Australian Information Commissioner.
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>10.0 Storage and security of personal information</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or
                          disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure personal information
                          and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.
                        </p>
                        <p>
                          While we are committed to security, we cannot guarantee the security of any information that is transmitted to or by us over the
                          Internet. The transmission and exchange of information is carried out at your own risk.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>11.0 Cookies</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We may use cookies on our website from time to time. Cookies are text files placed in your computer's browser to store your
                          preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. If and when you
                          choose to provide our online website with personal information, this information may be linked to the data stored in the cookie.
                        </p>
                        <p>
                          You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However,
                          if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our
                          website.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>12.0 Links to other websites</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Our website may contain links to other party’s websites. We do not have any control over those websites and we are not responsible for
                          the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not
                          governed by this Policy.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>13.0 Use of Google API Services</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We use Google’s Application Programming Interface (<b>API</b>) Service to make it easier for you to share data about your organisation
                          with us.
                        </p>
                        <p>
                          Our use of information received from Google APIs will adhere to the{" "}
                          <span className="underline">Google API Services User Data Policy</span>, including the Limited Use requirements (set out in the Google
                          API Services User Data Policy).
                        </p>
                        <p>
                          We limit our use of the personal information that we collect to providing or improving our Services. We do not use the personal
                          information for any other purposes.
                        </p>
                        <p>
                          We only transfer the personal information to others if it is necessary to provide or improve our Services or as necessary to comply
                          with applicable law or as part of a merger, acquisition or sale of assets where we notify you of this.
                        </p>
                        <p>
                          We do not use or transfer the personal information for serving ads, including retargeting, personalised or interest-based advertising.
                        </p>
                        <p>
                          These restrictions apply to the raw data we obtain from the restricted scopes and data that is aggregated, anonymised or derived from
                          them.
                        </p>
                        <p>
                          We have policies and procedures in place setting out appropriate safeguards for protecting your personal information obtained with the
                          Google APIs. We will also ensure that our employees, agents, contractors and successors comply with the Google API Services Data
                          Policy.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>14.0 Use of geo-localisation data</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>We collect your precise or approximate location via our mobile application for the following purposes:</p>
                        <ul className="sub-list">
                          <li>to prevent and detect fraud; and</li>
                          <li>as permitted by law.</li>
                        </ul>
                        <p>
                          If you do not want us to use your location for the purposes above, you should turn off the location services in your account settings
                          in our software (to the extent applicable) or in your mobile phone settings. If you do not provide location information to us, we may
                          not be able to provide our Services to you.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>15.0 Amendments &amp; contact</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We may, at any time and at our discretion, vary this Policy by publishing the amended Data and Privacy Policy on our website. We
                          recommend you check our website regularly to ensure you are aware of our current Data and Privacy Policy.
                        </p>
                        <p>For any questions or notices, please contact us at:</p>
                        <p>
                          <a href="mailto:support@hyfen8.com">support@hyfen8.com</a>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <img src={bannerImg} className="d-block d-md-none w-100" alt="bannerImg" />
      <section className="section2 cta-section">
        <div className="container">
          <div className="row m-0">
            <div className="col-12 col-md-6 px-0">
              <img src={bannerImg} className="d-none d-md-block" alt="bannerImg" />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center px-0">
              <div className="CTA">
                <p className="cta-content">
                  Do you have questions about our assessment process, the Hyfen8 platform or the Hyfen Eight
                  <sup className="registered">&reg;</sup>?
                </p>
                <p className="mail pt-3">
                  Get in touch with us at <a href="mailto:hello@hyfen8.com">hello@hyfen8.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DatPrivacyPage;
